<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="400px"
        min-width="400px"
        max-width="500px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ $t('message.sort') + ": " + $t('message.docTypes.' + standardShippingDocumentCategory) + 's' }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-row no-gutters class="pl-1 pr-3 pt-3">
                    <draggable
                        ghost-class="ghost"
                        v-model="sortedList"
                    >
                        <template v-for="(item, index) in sortedList">
                            <div :key="item.Document.doctype_id" class="d-flex flex-row align-center">
                                <span class="mr-2 font-weight-bold">{{ '#' + (index + 1).toString() }}</span>
                                <div :class="'sort-document' + (item.Document.id == standardShippingDocumentId ? ' success lighten-2' : '')" style="cursor: move">{{ item.Document.title }}</div>
                            </div>
                        </template>
                    </draggable>
                </v-row>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveStandardShippingDocumentSorting()"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from "vuex";
import draggable from 'vuedraggable';
// import { log } from "Helpers/helpers";
import { api } from "Api";

export default {
    name: "UpdateStandardShippingDocumentSorting",
    components: { draggable },
    props: ['standardShippingDocument','standardShippingDocumentCategory','standardShippingDocumentId','updateMode','dialog','pageKey'],
    data() {
        return {
            currentItemDragged: null,
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            sortedList: []
        }
    },
    computed: {
        ...mapGetters('document',{
            allDocuments: 'allDocuments'
        }),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        getStandardShippingDocumentSorting() {
            return new Promise((resolve, reject) => {
                api
                    .get('/documents/shipping/standard/' + this.standardShippingDocumentId + '/sorting/' + this.standardShippingDocumentCategory)
                    .then((response) => {
                        let list = []
                        if(response.data.status == 'success') {
                            list = response.data.data
                        }
                        resolve(list)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        saveStandardShippingDocumentSorting() {
            const sortList = this.sortedList.map( (item, index) => (
                {
                    sortId: index + 1,
                    document: item.Document.fieldname,
                    id: item.Document.id
                }
            ))
            const category = this.standardShippingDocumentCategory
            return new Promise((resolve, reject) => {
                api
                    .put('/documents/shipping/standard/' + this.standardShippingDocumentId + '/sorting', {
                        sortList: sortList,
                        category: category
                    })
                    .then((status) => {
                        if(status.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.standardShippingDocumentSortingUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.standardShippingDocumentSortingNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                        resolve('done')
                    })
                    .catch((err) => {
                        this.$toast.error(err,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                        reject(err)
                    })
            })
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getStandardShippingDocumentSorting()
                        .then(data => {
                            this.sortedList = data
                            this.loading.get = false
                        })
                        .catch(() => {
                            this.loading.get = false
                        })
                } else {
                    this.sortedList = this.allDocuments[this.standardShippingDocumentCategory]
                }
                this.loading.get = false
            } else {
                this.sortedList = []
            }
            this.edit_dialog = value
        }
    },
}
</script>

<style>
.sort-document {
    cursor: move;
    padding: 5px 10px;
    margin-bottom: 5px;
    border: dashed 1px grey;
    color: black;
    background-color: lightgray;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>